<template>
  <div>

    <svg
      id="Component_8_1"
      :class='"Component_8_1" + content.objectid'
      data-name="Component 8 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      focusable="false"
      viewBox="0 0 27 27"
       @click="toggleActivity"
    >
      <g id="Path_28" data-name="Path 28" :fill="liked?likedColor:noColor">
        <path
          d="M13.5,0A13.5,13.5,0,1,1,0,13.5,13.5,13.5,0,0,1,13.5,0Z"
          stroke="none"
        />
        <path
          d="M 13.5 1 C 10.16113090515137 1 7.022109985351562 2.300230026245117 4.661169052124023 4.661169052124023 C 2.300230026245117 7.022109985351562 1 10.16113090515137 1 13.5 C 1 16.83887100219727 2.300230026245117 19.97789001464844 4.661169052124023 22.33882904052734 C 7.022109985351562 24.69976997375488 10.16113090515137 26 13.5 26 C 16.83887100219727 26 19.97789001464844 24.69976997375488 22.33882904052734 22.33882904052734 C 24.69976997375488 19.97789001464844 26 16.83887100219727 26 13.5 C 26 10.16113090515137 24.69976997375488 7.022109985351562 22.33882904052734 4.661169052124023 C 19.97789001464844 2.300230026245117 16.83887100219727 1 13.5 1 M 13.5 0 C 20.95584106445312 0 27 6.044160842895508 27 13.5 C 27 20.95584106445312 20.95584106445312 27 13.5 27 C 6.044160842895508 27 0 20.95584106445312 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
          stroke="none"
          fill="#0073fa"
        />
      </g>
      <g
        id="thumb_up_black_24dp_1_"
        data-name="thumb_up_black_24dp (1)"
        transform="translate(5 5) rotate(360)"
      >
        <path id="Path_17" data-name="Path 17" d="M0,0H17V17H0Z" fill="none" />
        <path
          id="Path_18"
          :class='"Path_18" + content.objectid'
          data-name="Path 18"
          d="M1,14H3.727V6.2H1ZM16,6.85a1.337,1.337,0,0,0-1.364-1.3h-4.3l.648-2.97L11,2.371a.956.956,0,0,0-.3-.689L9.98,1,5.493,5.283a1.242,1.242,0,0,0-.4.917v6.5A1.337,1.337,0,0,0,6.455,14h6.136a1.357,1.357,0,0,0,1.255-.793L15.9,8.625A1.232,1.232,0,0,0,16,8.15Z"
          :fill="liked?thumbSelectedColor:thumbColor"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import { _projectName } from "@/provider-config.js";

export default {
  props: {
    content: {
      type: Object,
    },
    likeFireCount: {
      type: Number,
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      liked: false,
      likedColor: null,
      // likedColor: "#ffffff", //nammaflix color
      thumbSelectedColor:"#ffffff",
      noColor: "#161616",
      likeBlackColor: "black",
      thumbColor:"#ffffff",
      currentState: null,
      likeCount: 0,
      contentLikeCount: null,
      dislikeCountDisplay: null,
      applyColor: "clr-red",
      defaultColor: "like",
    };
  },
  watch: {
  
    contentLikeCount(val) {
      // console.log("contentLikeCount", val);
      this.$emit("likeCount", val);
      // eventBus.$emit("like-count-to-mixin", val);
    },
    likeFireCount(val) {
      console.log("content from parent", val);
    },
    content(val) {
      // console.log("watch content in like", val);
      this.fetchButtonState();
      this.getContentLikeCount();
      this.getContentDislikeCount();
    },
    subscriberid(val) {
      if (val) {
        this.fetchButtonState();
      }
    },
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },
  mounted() {
    document.getElementsByClassName(`Component_8_1${this.content.objectid}`)[0].addEventListener("mouseover", () => {
      document.getElementsByClassName(`Path_18${this.content.objectid}`)[0].setAttribute("fill", "white")
    });

    document.getElementsByClassName(`Component_8_1${this.content.objectid}`)[0].addEventListener("mouseout", () => {
      if (!this.liked)
      document.getElementsByClassName(`Path_18${this.content.objectid}`)[0].setAttribute("fill", "#ffffff")
    })


    this.getContentLikeCount();
    this.getContentDislikeCount();
    eventBus.$on("updatelikeDislikeStatus", () => {
      console.log("logged In from like no subscriber Button");
    });
  },
  created() {
    this.fetchButtonState();
/*    
this.likedColor =
      _projectName === "VLIVE"
        ? "#FF9600"
        : _projectName === "NET5"
        ? "#FF9600"
        : _projectName === "NAMMAFLIX"
        ? "#ffffff"
        : _projectName === "Noor Play"
        ? "#E81C25"
        : "";
*/
this.likedColor ="#0073fa"  
  // eventBus.$on("decrease-like-count", () => {
    //   eventBus.$emit("like-count-to-mixin", this.contentLikeCount);
    //   this.contentLikeCount =
    //     this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
    //   this.$emit("likeCount", this.contentLikeCount);
    // });
  },
  methods: {
    fetchButtonState() {
      // console.log("entered");
      if (!this.content) return;
      // console.log("content", this.content);
      let content = this.content;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      // console.log("button state path", path);
      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();

          if (response && response.likestatus === "LIKE") {
            this.liked = true;
            this.action = "REMOVE";
            this.currentState = "";
          } else if (response && response.likestatus === "DISLIKE") {
            this.action = "ADD";
            this.liked = false;
            console.log("current state from like", response.likestatus);
            this.currentState = "DISLIKE";
          } else {
            this.liked = false;
            this.action = "ADD";
            this.currentState = "";
          }
          // console.log("action in likebtn", this.action);
        });
    },
    getContentLikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "like",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.contentLikeCount = result;
        } else {
          this.contentLikeCount = 0;
        }
        // this.$emit("likeCount", this.contentLikeCount);
        // eventBus.$emit("like-count-to-mixin", this.contentLikeCount);
      });
    },
    getContentDislikeCount() {
      let payload = {
        objectid: this.content.objectid,
        type: "dislike",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.dislikeCountDisplay = result;
        } else {
          this.dislikeCountDisplay = 0;
        }
        // console.log("dislikeCount", this.dislikeCountDisplay);
        // this.$emit("dislikeCount", this.dislikeCountDisplay);
        // eventBus.$emit("dislike-count-to-mixin", this.dislikeCountDisplay);
      });
    },
    toggleActivity() {

      if (this.subscriberid) {
        // this.$emit("updateLikeCount", this.action);
        if (this.action === "ADD" && this.currentState === "") {
          // console.log("add");
          // this.updateContentCount(this.content, this.contentLikeCount, "LIKE");
          this.updateLikeDislikeToFire(this.content, "LIKE");
          //calculate counts
          this.contentLikeCount = this.contentLikeCount + 1;
          this.$emit("likeCount", this.contentLikeCount);
        } else if (this.action === "ADD" && this.currentState === "DISLIKE") {

          // when the content is DISLIKE change the status and count
          console.log("dislikeCountDisplay", this.dislikeCountDisplay);
          this.updateContentCount(
            this.content,
            this.contentLikeCount,
            "LIKE",
            this.dislikeCountDisplay
          );
          this.updateLikeDislikeToFire(this.content, "LIKE");
          this.contentLikeCount = this.contentLikeCount + 1;
          // this.dislikeCountDisplay =
          //   this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
          this.$emit("likeCount", this.contentLikeCount);
          this.$emit("dislikeCount", this.dislikeCountDisplay);
        } else if (this.action === "REMOVE") {

          // console.log("remove");
          this.updateContentCount(
            this.content,
            this.contentLikeCount,
            "REMOVELIKE"
          );
          this.updateLikeDislikeToFire(this.content, "NONE");

          //calculate counts
          this.contentLikeCount =
            this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
          this.$emit("likeCount", this.contentLikeCount);
        }
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
  },
  mixins: [FirebaseActions],
};
</script>

<style>
@import "./LikeBtn.scss"
</style>
